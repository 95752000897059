import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import PropTypes from "prop-types";

import SideMenu from "./SideMenu";
import FullScreenLoader from "../../components/Loaders/FullScreenLoader";
import { RootState } from "../../redux/store";
import styled from "styled-components";
import { NavWrapper, BackLink, BackArrow } from "../../shared/Nav.styled";

import {
  CheckboxLabel,
  StyledCard,
  Title,
  MainWrapper,
  StatusWrapper,
  SectionTitle,
} from "./SurveyConfiguration.styled";
import { getSurveyConfig } from "../../redux/surveyConfig/surveyConfigActions";
import { fetchSurveys } from "../../redux/surveyList/surveysActions";
import { setActiveSurvey } from "../../redux/surveyList/surveysSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Result, Button, Tag, Alert, Col, message } from "antd";
import {
  InfoCircleFilled,
  InfoCircleOutlined,
  LayoutFilled,
  MobileOutlined,
  PushpinFilled,
  UserOutlined,
  InsertRowRightOutlined,
  HomeFilled,
  MailFilled,
  ProfileFilled,
  ReadFilled,
  ControlOutlined,
  BuildFilled,
  AudioOutlined,
  TableOutlined,
  MailOutlined,
  FormOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  HourglassOutlined,
  ExclamationCircleOutlined,
  RiseOutlined,
  IssuesCloseOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import { userHasPermission, isAdmin } from "../../utils/helper";
import { GlobalStyle } from "../../shared/Global.styled";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import SurveyState from "../../components/SurveyState";

interface CheckboxProps {
  checked: boolean;
  color: string;
}

const CustomCheckbox: React.FC<CheckboxProps> = ({ checked, color }) => {
  const CheckboxWrapper = styled.div`
    width: 1rem;
    height: 1rem;
    border-radius: 4px;
    background-color: ${checked ? color : "transparent"};
    border: 1px solid ${color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    cursor: default;
  `;
  return (
    <CheckboxWrapper>
      {checked && <CheckboxLabel>✓</CheckboxLabel>}
    </CheckboxWrapper>
  );
};

CustomCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
};

const sectionRoutes: { [key: string]: string } = {
  "Basic information": "new-survey-config",
  "Module selection": "module-selection",
  "Survey information": "survey-information",
  "Module configuration": "module-configuration",
};

const itemRoutes: { [key: string]: { [key: string]: string } } = {
  "Survey information": {
    "SurveyCTO information": "survey-cto-information",
    "User and role management": "survey-roles/roles",
    "Survey locations": "location/upload",
    Enumerators: "enumerators",
    Targets: "targets",
    "Target status mapping": "survey/status-mapping",
    Mapping: "mapping/surveyor",
  },
  "Module configuration": {
    "Assign targets to surveyors": "assignments",
    "Assignments column configuration": "table-config",
    Emails: "emails",
    "Media (Audio/Photo) audits": "media-audits",
    "Track data quality": "dq-forms",
    "Admin forms": "admin-forms",
  },
};

const SurveyConfiguration: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [errorModules, setErrorModules] = useState<
    { section: string; item: string }[]
  >([]);
  const [noErrorModules, setNoErrorModules] = useState<
    { section: string; item: string }[]
  >([]);

  const { survey_uid } = useParams<{ survey_uid?: string }>() ?? {
    survey_uid: "",
  };
  const activeSurvey = useAppSelector(
    (state: RootState) => state.surveys.activeSurvey
  );

  const handleGoBack = () => {
    navigate("/surveys"); // Navigate to the surveys page
  };

  const surveyConfigs = useAppSelector(
    (state: RootState) => state.surveyConfig.surveyConfigs
  );

  const completionStats = useAppSelector(
    (state: RootState) => state.surveyConfig.completionStats
  );

  const isLoading = useAppSelector(
    (state: RootState) => state.surveyConfig.loading
  );
  const userProfile = useAppSelector((state: RootState) => state.auth.profile);

  const fetchData = async () => {
    const surveyConfigRes = await dispatch(
      getSurveyConfig({ survey_uid: survey_uid })
    );
  };

  const [isSurveyLoading, setIsSurveyLoading] = useState<boolean>(true);

  const renderStatus = (status: string) => {
    const colors: { [key: string]: string } = {
      Done: "green",
      "In Progress": "yellow",
      "In Progress - Incomplete": "orange",
      Error: "red",
      Live: "green",
      Paused: "orange",
    };
    const color = colors[status];
    const icons: { [key: string]: any } = {
      Done: CheckCircleOutlined,
      "In Progress": SyncOutlined,
      "In Progress - Incomplete": IssuesCloseOutlined,
      Error: CloseCircleOutlined,
      Live: RiseOutlined,
      Paused: PauseCircleOutlined,
    };
    const IconComponent = icons[status] || HourglassOutlined;

    return (
      <StatusWrapper>
        <Tag icon={<IconComponent />} color={color}>
          {status}
        </Tag>
      </StatusWrapper>
    );
  };

  const generateLink = (sectionTitle: string, itemName: string) => {
    const sectionRoute = sectionRoutes[sectionTitle];

    if (sectionRoute) {
      const itemRoute = itemRoutes[sectionTitle]?.[itemName];

      if (itemRoute) {
        return `/${sectionRoute}/${itemRoute}/${survey_uid}`;
      }

      return `/${sectionRoute}/${survey_uid}`;
    }

    return "";
  };

  const renderModuleIcon = (sectionTitle: string) => {
    const iconProps = { fontSize: "28px" };

    switch (sectionTitle) {
      case "Basic information":
        return (
          <InfoCircleOutlined style={{ color: "#391085", ...iconProps }} />
        );
      case "Module selection":
        return <LayoutFilled style={{ color: "#7CB305", ...iconProps }} />;

      case "Survey locations":
        return <PushpinFilled style={{ color: "#FAAD14", ...iconProps }} />;
      case "User and role management":
        return <UserOutlined style={{ color: "#D4380D", ...iconProps }} />;
      case "SurveyCTO information":
        return <MobileOutlined style={{ color: "#1D39C4", ...iconProps }} />;
      case "Enumerators":
        return (
          <InsertRowRightOutlined style={{ color: "#C41D7F", ...iconProps }} />
        );
      case "Targets":
        return <HomeFilled style={{ color: "#389E0D", ...iconProps }} />;
      case "Mapping":
        return <ControlOutlined style={{ color: "#531DAB", ...iconProps }} />;
      case "Target status mapping":
        return <BuildFilled style={{ color: "#D4380D", ...iconProps }} />;
      case "Assign targets to surveyors":
        return <MailFilled style={{ color: "#cf1322", ...iconProps }} />;
      case "Emails":
        return <MailOutlined style={{ color: "#389E0D", ...iconProps }} />;
      case "Assignments column configuration":
        return <TableOutlined style={{ color: "#1D39C4", ...iconProps }} />;
      case "Track productivity":
        return <ProfileFilled style={{ color: "#FAAD14", ...iconProps }} />;
      case "Track data quality":
        return <ReadFilled style={{ color: "#7CB305", ...iconProps }} />;
      case "Media (Audio/Photo) audits":
        return <AudioOutlined style={{ color: "#08979C", ...iconProps }} />;
      case "Admin forms":
        return <FormOutlined style={{ color: "#8308cf", ...iconProps }} />;
      default:
        return <InfoCircleFilled style={{ color: "#FAAD14", ...iconProps }} />;
    }
  };

  const checkPermissions = (sectionTitle: string) => {
    let permission_name: string;

    switch (sectionTitle) {
      case "Basic information":
        permission_name = "Survey Admin";
        break;
      case "Module selection":
        permission_name = "Survey Admin";
        break;
      case "Survey locations":
        permission_name = "READ Survey Locations";
        break;
      case "User and role management":
        permission_name = "Survey Admin";
        break;
      case "SurveyCTO information":
        permission_name = "Survey Admin";
        break;
      case "Enumerators":
        permission_name = "READ Enumerators";
        break;
      case "Targets":
        permission_name = "READ Targets";
        break;
      case "Mapping":
        permission_name = "READ Mapping";
        break;
      case "Target status mapping":
        permission_name = "READ Target Status Mapping";
        break;
      case "Assign targets to surveyors":
        permission_name = "READ Assignments";
        break;
      case "Media (Audio/Photo) audits":
        permission_name = "READ Media Files Config";
        break;
      case "Assignments column configuration":
        permission_name = "READ Assignments";
        break;
      case "Emails":
        permission_name = "READ Emails";
        break;
      case "Track productivity":
        permission_name = "READ Productivity";
        break;
      case "Track data quality":
        permission_name = "READ Data Quality";
        break;
      default:
        permission_name = sectionTitle;
        break;
    }
    return userHasPermission(userProfile, survey_uid, permission_name);
  };

  const renderSection = (sectionTitle: string, sectionConfig: any) => {
    if (Array.isArray(sectionConfig) && sectionConfig.length > 0) {
      return (
        <>
          {sectionConfig.some((item: any) => checkPermissions(item?.name)) && (
            <SectionTitle>{`${sectionTitle}`}</SectionTitle>
          )}

          <div style={{ flexWrap: "wrap", display: "flex" }}>
            {sectionConfig.map((item: any, i: number) => {
              const hasPermission = checkPermissions(item?.name);
              // Hide the productivity module
              return hasPermission && item?.name != "Track productivity" ? (
                <Link
                  key={i}
                  style={{
                    width: 309,
                    display: "inline-block",
                    color: "#434343",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  to={generateLink(sectionTitle, item.name)}
                >
                  <StyledCard style={{ margin: "0.2rem", height: 165 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "left",
                        marginBottom: "10px",
                      }}
                    >
                      {renderModuleIcon(item.name)}
                    </div>
                    {item.name} {item.optional && "(Optional)"}
                    {renderStatus(item.status)}
                  </StyledCard>
                </Link>
              ) : null;
            })}
          </div>
        </>
      );
    } else if (
      !Array.isArray(sectionConfig) &&
      Object.keys(sectionConfig).length > 0
    ) {
      const hasPermission = checkPermissions(sectionTitle);

      return hasPermission ? (
        <>
          <SectionTitle>{`${sectionTitle}`}</SectionTitle>

          <Link
            style={{
              width: 309,
              display: "inline-block",
              color: "#434343",
              cursor: "pointer",
              textDecoration: "none",
            }}
            to={generateLink(sectionTitle, "")}
          >
            <StyledCard style={{ height: 165 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "left",
                  marginBottom: "10px",
                }}
              >
                {renderModuleIcon(sectionTitle)}
              </div>

              {sectionTitle}
              {renderStatus(sectionConfig.status)}
            </StyledCard>
          </Link>
        </>
      ) : null;
    } else {
      <Result
        title={"Reload Configuration"}
        subTitle={"Failed to load configuration, kindly reload"}
        extra={
          <Button
            onClick={fetchData}
            type="primary"
            className="bg-geekblue-5 h-[40px]"
            size="large"
          >
            Reload Data
          </Button>
        }
      />;
    }
  };

  useEffect(() => {
    fetchData();
  }, [survey_uid]);

  useEffect(() => {
    if (survey_uid && !activeSurvey) {
      // fetch survey list
      dispatch(fetchSurveys()).then((surveyList) => {
        if (surveyList.payload?.length > 0) {
          const surveyInfo = surveyList.payload.find(
            (survey: any) => survey.survey_uid === parseInt(survey_uid)
          );

          if (!surveyInfo) {
            message.error("Survey not found");
            navigate("/surveys");
            return;
          }

          // set the active survey
          dispatch(
            setActiveSurvey({
              survey_uid,
              survey_name: surveyInfo?.survey_name || "",
              state: surveyInfo?.state || "",
            })
          );
        }
      });
    }
  }, [survey_uid]);

  useEffect(() => {
    const errors: { section: string; item: string }[] = [];
    const noErrors: { section: string; item: string }[] = [];

    Object.entries(surveyConfigs).forEach(([sectionTitle, sectionConfig]) => {
      if (Array.isArray(sectionConfig)) {
        sectionConfig.forEach((item: any) => {
          if (item.status === "Error") {
            errors.push({ section: sectionTitle, item: item.name });
          } else {
            if (checkPermissions(item.name)) {
              noErrors.push({ section: sectionTitle, item: item.name });
            }
          }
        });
      } else if (sectionConfig.status === "Error") {
        errors.push({ section: sectionTitle, item: "" });
      } else {
        if (checkPermissions(sectionTitle)) {
          noErrors.push({ section: sectionTitle, item: "" });
        }
      }
    });
    setErrorModules(errors);
    setNoErrorModules(noErrors);
  }, [surveyConfigs]);

  const { height } = useWindowDimensions();

  return (
    <>
      <GlobalStyle />

      <NavWrapper>
        <BackLink onClick={handleGoBack}>
          <BackArrow />
        </BackLink>
        <Title>
          Survey configuration
          {(() => {
            const activeSurveyData: any = localStorage.getItem("activeSurvey");
            return ` : ${
              activeSurvey?.survey_name ||
              (activeSurveyData && JSON.parse(activeSurveyData)?.survey_name) ||
              ""
            }`;
          })()}
        </Title>
        <SurveyState
          survey_uid={survey_uid || ""}
          survey_name={(() => {
            const activeSurveyData: any = localStorage.getItem("activeSurvey");
            return (
              activeSurvey?.survey_name ||
              (activeSurveyData && JSON.parse(activeSurveyData)?.survey_name) ||
              ""
            );
          })()}
          state={(() => {
            const activeSurveyData: any = localStorage.getItem("activeSurvey");
            return (
              activeSurvey?.state ||
              (activeSurveyData && JSON.parse(activeSurveyData)?.state) ||
              ""
            );
          })()}
          error={errorModules.length > 0}
          can_edit={isAdmin(userProfile, survey_uid)}
        />
      </NavWrapper>
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <>
          {errorModules.length > 0 && (
            <>
              <Alert
                message={`Modules in Error. ${
                  activeSurvey?.state === "Active"
                    ? "Survey will be paused until these errors are resolved."
                    : ""
                }`}
                description={
                  <span style={{ fontSize: "14px", font: "Lato" }}>
                    The following modules are in error:
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <Col span={4} style={{ flex: "1 1 50%" }}>
                        <ul style={{ paddingLeft: "20px" }}>
                          {errorModules
                            .filter((_, index) => index % 2 === 0)
                            .map((module, index) => (
                              <li key={index}>
                                <Link
                                  to={
                                    checkPermissions(module.item)
                                      ? generateLink(
                                          module.section,
                                          module.item
                                        )
                                      : ""
                                  }
                                >
                                  {module.item || module.section}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </Col>
                      <Col span={5} style={{ flex: "1 1 50%" }}>
                        <ul>
                          {errorModules
                            .filter((_, index) => index % 2 !== 0)
                            .map((module, index) => (
                              <li key={index}>
                                <Link
                                  to={
                                    checkPermissions(module.item)
                                      ? generateLink(
                                          module.section,
                                          module.item
                                        )
                                      : ""
                                  }
                                >
                                  {module.item || module.section}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </Col>
                    </div>
                    {!isAdmin(userProfile, survey_uid) ? (
                      <span>
                        Access restricted to following modules until the errors
                        are resolved.
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <Col span={4} style={{ flex: "1 1 50%" }}>
                            <ul style={{ paddingLeft: "20px" }}>
                              {noErrorModules
                                .filter((_, index) => index % 2 === 0)
                                .map((module, index) => (
                                  <li key={index}>
                                    <Link
                                      to={
                                        checkPermissions(module.item)
                                          ? generateLink(
                                              module.section,
                                              module.item
                                            )
                                          : ""
                                      }
                                    >
                                      {module.item || module.section}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          </Col>
                          <Col span={5} style={{ flex: "1 1 50%" }}>
                            <ul>
                              {noErrorModules
                                .filter((_, index) => index % 2 !== 0)
                                .map((module, index) => (
                                  <li key={index}>
                                    <Link
                                      to={
                                        checkPermissions(module.item)
                                          ? generateLink(
                                              module.section,
                                              module.item
                                            )
                                          : ""
                                      }
                                    >
                                      {module.item || module.section}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          </Col>
                        </div>
                        <span>
                          Kindly contact your Survey Admin to resolve the issues
                          above to continue.
                        </span>
                      </span>
                    ) : (
                      <span>Kindly resolve issues in above modules.</span>
                    )}
                  </span>
                }
                type="error"
                showIcon
                icon={
                  <ExclamationCircleOutlined
                    style={{ fontSize: "24px", color: "#ff4d4f" }}
                  />
                }
                style={{
                  padding: "16px",
                  borderRadius: "4px",
                  backgroundColor: "#fff1f0",
                  border: "1px solid #ffccc7",
                }}
              />
            </>
          )}
          <div style={{ display: "flex" }}>
            <SideMenu
              surveyProgress={surveyConfigs}
              completionStats={completionStats}
              windowHeight={height}
            />
            <MainWrapper windowHeight={height}>
              {Object.entries(surveyConfigs).map(
                ([sectionTitle, sectionConfig], index) => (
                  <div key={index}>
                    {renderSection(sectionTitle, sectionConfig)}
                  </div>
                )
              )}
            </MainWrapper>
          </div>
        </>
      )}
    </>
  );
};

export default SurveyConfiguration;
