export const surveyConfigs: Record<string, any> = {
  "Basic information": {
    status: "Not Started",
    optional: false,
  },
  "Module selection": {
    status: "Not Started",
    optional: false,
  },
  "Survey information": [
    {
      name: "SurveyCTO information",
      status: "Not Started",
      optional: false,
    },
    {
      name: "User and role management",
      status: "Not Started",
      optional: false,
    },
    {
      name: "Survey locations",
      status: "Not Started",
      optional: true,
    },
    {
      name: "Enumerators",
      status: "Not Started",
      optional: true,
    },
    {
      name: "Targets",
      status: "Not Started",
      optional: true,
    },
    {
      name: "Target status mapping",
      status: "Not Started",
      optional: true,
    },
    {
      name: "Mapping",
      status: "Not Started",
      optional: true,
    },
  ],
  "Module configuration": [],
};

export const completionStats = {
  num_modules: 0,
  num_completed: 0,
  num_in_progress: 0,
  num_in_progress_incomplete: 0,
  num_not_started: 0,
  num_error: 0,
  num_optional: 0,
};
